<template>
    <common-modal :open="videoIndex != null" :width="'80%'" @close="$emit('close')" >
        <v-card class="pa-0 video-container">
            <v-card-text class="pa-0 pa-3 position-relative" :class="{ 'pb-0': captureLists.length}" >
                <v-carousel hide-delimiters v-model="activeIndex">
                    <v-carousel-item v-for="(item, index) in videoLists" :key="item.id">
                        <v-sheet height="100%" tile>
                            <v-row class="fill-height" align="center" justify="center" :id="`video-section-${index}`">
                                <vue-plyr :ref="`plyr${index}`" class="w-full">
                                    <video controls playsinline :id="'player_'+index" crossorigin="anonymous">
                                        <source :src="item.src" type="video/mp4">
                                    </video>
                                </vue-plyr>
                                 <v-btn depressed color="info" class="capture-button" :class="{'opacity-low': !item.captureEnabled}"
                                    @click="item.captureEnabled && captureImg(`player_${index}`)">Capture
                                    <v-icon right dark> mdi-fit-to-screen</v-icon>
                                </v-btn>
                            </v-row>
                        </v-sheet>
                    </v-carousel-item>
                </v-carousel>
            </v-card-text>
            <v-divider v-if="captureLists.length"></v-divider>
            <v-card-actions class="capture-container" v-if="captureLists.length">
                <v-slide-group mandatory show-arrows >
                    <v-slide-item v-for="(item, index) in captureLists" :key="index">
                        <div class="cursor-pointer position-relative pa-2 capture-image">
                            <v-img :src="item.src" width="100" height="100" @click="viewImg(index)"/>
                            <v-btn color="white" v-if="item.saved" fab x-small dark class="position-absolute" style="left: 35%; top: 35%;"
                                @click="$event.stopPropagation();">
                                <v-icon size="34" color="success">mdi-checkbox-marked-circle</v-icon>
                            </v-btn>     
                            <v-btn color="white" v-else fab x-small dark class="position-absolute" style="left: 35%; top: 35%;"
                                @click="item.saved = true; $emit('save', item.src)">
                                <v-icon size="34" color="info">mdi-download-circle</v-icon>
                            </v-btn>
                            <v-icon size="25" v-if="!item.saved" color="secondary darken-3" class="rounded-circle bg-white position-absolute" style="right: 0px; top: 0px;"
                                @click="captureLists.splice(index, 1)">mdi-minus-circle</v-icon>
                        </div>
                    </v-slide-item>
                </v-slide-group>
            </v-card-actions>
        </v-card>
    </common-modal>
</template>

<script>
import VuePlyr from 'vue-plyr';
import 'vue-plyr/dist/vue-plyr.css';
import { mapGetters } from 'vuex';

import { CommonModal } from "@/components/common";

export default {
    props: ['videoIndex', 'lists'],
    emits: ['close', 'save'],
    components: { VuePlyr, CommonModal },
    data: (vm) => ({
        captureLists: [],
        activeIndex: 0,
        videoLists: []
    }),
    computed: {
        ...mapGetters(["scanLoading"]),
    },
    watch: {
        videoIndex(newVal) {
            this.activeIndex = newVal || 0;
        },
        activeIndex(newVal) {
            if (this.videoIndex != newVal ) {
                setTimeout(() => {
                    this.$refs[`plyr${newVal}`][0].player.on('playing', () => {
                        const ITEMS = this.videoLists[newVal];
                        if(!ITEMS.captureEnabled ) this.videoLists[newVal].captureEnabled = true;
                        this.videoLists.splice(newVal, 1, ITEMS);
                    });
                }, 500);
            }
        },
        videoLists(newVal) {
            if (newVal.length) this.videoLists = newVal;
        }
    },
    mounted() {
        this.$refs[`plyr${this.videoIndex}`][0].player.on('playing', () => {
            const ITEMS = this.videoLists[this.activeIndex];
            if(!ITEMS.captureEnabled ) ITEMS.captureEnabled = true;
            this.videoLists.splice(this.activeIndex, 1, ITEMS);
        });
    },
    created() {
        this.activeIndex = this.videoIndex || 0;
        this.videoLists = this.lists || [];
    },
    methods: {
        async captureImg(id) {
            const video = document.getElementById(id),
                canvasURL = await this.$helpers.captureImgFromVdo(video, 0.25);
            this.captureLists.unshift({src: canvasURL, saved: false});
        },
        viewImg(index) {
            const image = [];
            image.push(this.captureLists[index].src);
            this.$viewerApi({images: image});
        }
    }
}
</script>

<style>

</style>